import { FunctionComponent, useContext } from 'react';
import dynamic from 'next/dynamic';

import DeviceContext from 'components/context/Device';
import Button, { ButtonElements } from 'components/layout/Button';
import LayoutContainer from 'components/layout/LayoutContainer';
import MediumBlogBox from 'components/modules/public/blogs/MediumBlogBox';

import StyledComponent from './styles';
import { Props } from './types';

const MobileSlider = dynamic(() => import('components/layout/MobileSlider'));

const PublicBlogsSectionBlogs: FunctionComponent<Props> = ({
    articles,
    translations,
}) => {
    if (articles?.length === 0) return null;
    const deviceContext = useContext(DeviceContext);

    const {
        headline,
        buttonLabel,
        buttonHref,
    } = translations;

    const articleItems = articles.map(article => (
        <a
            key={article.id}
            className="link"
            onClick={() => {
                window.location.href = article.href;
            }}
        >
            <MediumBlogBox article={article} />
        </a>
    ));

    return (
        <StyledComponent className="public-blogs-section-blogs">
            <LayoutContainer className="mobile-marginless">
                {headline && (
                    <div className="headline">
                        <h2 dangerouslySetInnerHTML={{ __html: headline }} />
                    </div>
                )}
                <div className="blog-content">
                    {!deviceContext?.isMobile && (
                        <div className="blogs-boxes blog-desktop">
                            {articleItems}
                        </div>
                    )}
                    {deviceContext?.isMobile && (
                        <MobileSlider
                            showNormalDots={true}
                            sliderProps={{
                                infinite: false,
                                initialSlide: 0,
                            }}
                        >
                            {articleItems}
                        </MobileSlider>
                    )}
                </div>
                {buttonLabel && (
                    <div className="control">
                        <Button
                            element={ButtonElements.Anchor}
                            href={buttonHref}
                        >
                            {buttonLabel}
                        </Button>
                    </div>
                )}
            </LayoutContainer>
        </StyledComponent>
    );
};

export default PublicBlogsSectionBlogs;
