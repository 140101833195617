import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
	width: 100%;
	height: 100%;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	box-shadow: -2px 1px 10px #00000029;

	.image-container {
		overflow: hidden;
		min-height: 160px;
		max-height: 160px;
		border-top-left-radius: 1em;
		border-top-right-radius: 1em;

		.blog-box-image {
			width: 100%;
			height: 100%;
			transform: scale(1.1);
			transition: 0.3s transform ease-in-out;
			object-fit: cover;
		}
	}

	.blog-box-content {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		padding: 1em;

        .content {
            .title {
                font-weight: 700;
                font-size: .9em;
            }

			.description {
				margin: 1em 0;
				font-size: 0.8em;
				color: #9d9d9c;
			}
		}

		.link {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			text-decoration: none;
			padding-top: 0.5em;

			.layout-button {
				justify-content: center;
				width: 100%;

				.button {
					.button-content {
						font-size: 1em;
						color: #ba9449;
						text-transform: uppercase;
						letter-spacing: 0.3em;
					}

					.button-icon-image {
						width: max-content;
					}
				}
			}
		}
	}

	&:hover {
		.image-container {
			.blog-box-image {
				transform: scale(1);
			}
		}

		.blog-box-content {
			.link {
				.layout-button {
					.button {
						.button-icon-after {
							transform: translateX(20%);
						}
					}
				}
			}
		}
	}

	@media all and (max-width: ${variables.mobileM}) {
		.blog-box-content {
			font-size: 140%;

			.link {
				.layout-button {
					.button {
						.button-content {
							font-size: 0.8em;
						}
					}
				}
			}
		}
	}
`;
