import { FunctionComponent, useContext } from 'react';

import { shorten } from 'utils/string';

import LocaleContext from 'components/context/Locale';
import Button, { ButtonIconPositions, ButtonIconTypes, ButtonLayouts, ButtonVariants } from 'components/layout/Button';
import NewImage from 'components/layout/NewImage';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutBlogsMediumBlogBox: FunctionComponent<Props> = ({ article, translations }) => {
    const localeContext = useContext(LocaleContext);

    translations = {
        ...localeContext?.translations?.componentBlogsMediumBlogBox,
        ...(translations || {}),
    };

    return (
        <StyledComponent className="public-layout-blogs-medium-blog-box">
            <div className="image-container">
                {article?.locale?.image?.imageUrl && (
                    <NewImage
                        className="blog-box-image"
                        resizerConfig={{ width: 500 }}
                        fileUpload={article?.locale?.image}
                        alt={article?.locale?.title}
                    />
                )}
            </div>
            <div className="blog-box-content">
                <div className="content">
                    {article?.locale?.title && (
                        <div className="title">
                            {shorten(article?.locale?.title, 60, true)}
                        </div>
                    )}
                    {article?.locale?.lead && (
                        <div className="description">
                            {shorten(article?.locale?.lead, 120, true)}
                        </div>
                    )}
                </div>
                <a
                    href={article?.href}
                >
                    <span className="link">
                        <Button
                            {...{
                                ...translations?.buttonProps,
                                layout: ButtonLayouts.Wide,
                                variant: ButtonVariants.Outline,
                                className: 'arrow',
                                icon: {
                                    value: '/images/home/arrow-small.svg',
                                    position: ButtonIconPositions.After,
                                    type: ButtonIconTypes.Image,
                                },
                            }}
                        />
                    </span>
                </a>
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutBlogsMediumBlogBox;
