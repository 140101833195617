import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
	position: relative;
	width: 100%;

	.headline {
		font-size: 1.7em;
		margin-bottom: 2em;
		font-weight: 700;
		color: #262626;
		text-align: center;
	}

	.blog-content {
		.link {
			text-decoration: none;
			color: #262626;
		}

		.blogs-boxes {
			display: flex;
			justify-content: flex-start;
			align-items: stretch;
			flex-wrap: wrap;

			> * {
				margin: 0.8em;
				width: calc(33.3% - 1.6em);
			}
		}
	}

	.control {
		margin-top: 2em;
	}

	@media all and (max-width: ${variables.tabletL}) {
		.blog-content {
			.blogs-boxes {
				> * {
					margin: 1em;
					width: calc(50% - 2em);
				}
			}
		}
	}

	@media all and (max-width: ${variables.mobileL}) {
		overflow-x: hidden;

		.slick-list {
			padding: 0 !important;
		}

		.mobile-marginless {
			width: 100%;
		}

		.blog-content {
			margin-bottom: 1.6em;

			.blogs-boxes {
				justify-content: center;

				&.blog-desktop {
					display: none;
				}

				> * {
					width: 70%;
				}
			}
		}

		.control {
			font-size: 130%;
		}
	}

	@media all and (max-width: ${variables.mobileM}) {
		.blog-content {
			.blogs-boxes {
				> * {
					width: 100%;
				}
			}
		}
	}
`;
