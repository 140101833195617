import { FunctionComponent } from 'react';

import SectionBlogs from 'components/modules/public/blogs/SectionBlogs';

import StyledComponent from './styles';
import { Props } from './types';

const PublicSectionBlogsVariantDefault: FunctionComponent<Props> = ({
    ...props
}) => {
    return (
        <StyledComponent className="public-section-blogs-variant-default" >
            <SectionBlogs {...props} />
        </StyledComponent>
    );
};

export default PublicSectionBlogsVariantDefault;
